import i18n from "@/libs/i18n";

const fields = [
    {
        key: 'title',
        label: i18n.t('title'),
    },
    {
        key: 'imdb',
        label: i18n.t('imdb'),
    },
    {
        key: 'user.name',
        label: i18n.t('writer'),
    },
    {
        key: 'status',
        label: i18n.t('status'),
    },
    {
        key: 'published_at',
        label: i18n.t('published_at'),
    },
    {
        key: 'action',
        label: i18n.t('action'),
    },
]

export default fields
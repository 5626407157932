<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-1">
            <div class="">
                <b-button
                    :to="{ name:'create-post'}"
                    class="mr-1"
                    variant="relief-primary"
                >
                    {{ $t('new') }}
                </b-button>
                <b-button
                    class="mr-1"
                    variant="info"
                    @click="visible = !visible"
                >
                    {{ $t('search') }}
                </b-button>
                <b-button
                    variant="success"
                    @click="$router.go(-1)"
                >
                    {{ $t('back') }}
                </b-button>
            </div>
            <div>
                <span>تعداد کل: </span>
                {{ pagination.total }}
            </div>
        </div>
        <custom-filter
            :filters="filters"
            :visible="visible"
            @onSubmit="filters => filter(filters)"
        >
            <template #form="{filters}">
                <b-row>
                    <b-col md="6">
                        <custom-input
                            v-model="filters.title"
                            :name="$t('title')"
                            vid="title"
                        />
                    </b-col>
                </b-row>
            </template>
        </custom-filter>
        <b-card no-body>
            <b-table
                :busy="status === 'LOADING'"
                :fields="fields"
                :items="Object.values(posts)"
                hover
                responsive
            >
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle" />
                    </div>
                </template>
                <template #cell(action)="data">
                    <b-dropdown
                        no-caret
                        variant="link"
                    >
                        <template #button-content>
                            <feather-icon icon="MoreHorizontalIcon" />
                        </template>
                        <b-dropdown-item @click="restore(data.item)">
                            <feather-icon icon="RefreshCwIcon" />
                            {{ $t('restore') }}
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
            <b-pagination
                v-if="pagination.last_page > 1"
                v-model="pagination.current_page"
                :per-page="pagination.per_page"
                :total-rows="pagination.total"
                align="center"
                class="mt-2"
                @change="paginationCategory"
            />
        </b-card>
    </div>

</template>

<script>
import fields from "./partials/fields";
import { mapActions, mapGetters } from "vuex";
import { postService } from "@/libs/api";

export default {
    name: "Posts",

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchPosts()
        })
    },

    data() {
        return {
            fields,
            visible: false,
            filters: {
                title: '',
            }
        }
    },

    computed: {
        ...mapGetters('post', [
            'posts', 'status', 'pagination'
        ]),
        ...mapGetters('filter', [
            'filterFields'
        ])
    },

    methods: {
        ...mapActions({
            fetchPosts: 'post/fetchDeleted',
            restorePost: 'post/restore'
        }),
        filter(filters) {
            this.fetchPosts({
                ...filters,
                page: 1
            })
        },
        paginationCategory(page) {
            this.fetchPosts({
                page,
                ...this.filterFields
            })
        },
        restore(item) {
            this.$swal({
                title: this.$t('Are you sure?'),
                // text: this.$t("You won't be able to revert this!"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('Yes, restore it!'),
                cancelButtonText: this.$t('No'),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.restorePost(item.id).then(()=> {
                        this.$swal({
                            icon: 'success',
                            title: this.$t(`Restored!`),
                            text: this.$t('Your file has been restored.'),
                            confirmButtonText: this.$t('Ok!'),
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                    })
                }
            })
        }
    }
}
</script>
